import { createStore } from 'vuex'
import locations from "@/assets/locations-map.json"
import axios from "axios";
import {csv} from "d3-fetch";

const server = process.env.VUE_APP_SERVER;
const latestIndicesNum = 5;
const labelSize = 5;


export default createStore({
  state: {
    locations,
    homeIndex : null,
    indicesList : null,
    indices : [],
    shortList : [],
    server
  },
  getters : {
    getCategory : state => slug =>  {
      if(!state.indicesList) return null;

      return state.indicesList.find(d => d.slug == slug);
    },

    getCsvFromArray : () => items => {
      const replacer = (key, value) => value === null ? '' : value 
      const header = Object.keys(items[0])
      let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')

      return csv;
    },

    getLatestIndices : state => {
      if(!state.indicesList) return [];

      const collection = state.indicesList.map(d => d.indices).flat();
      return collection.sort( (a,b) => new Date(a.update) > new Date(b.update) ? -1: 1 ).slice(0,latestIndicesNum)
    },

    translateLocation : state => value => {
      let isNumber = !isNaN( Number(value) ),
          key,
          str1;
      
      if(isNumber){
        key = +value;
        return state.locations.find(d => +d.CVEGEO == key || +d.CVE_MUN == key)
      }
      else{
        str1 = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return state.locations.find(d => d.NOMGEO.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(str1) != -1)
      }
    },

    translateLabel : state => value => {
      const item = state.shortList.find(d => d.text == value);
      if(item){
        return item.short
      }
      else{
        return String(value).substring(0, labelSize);
      }
    }
  },

  mutations: {
    saveHomeIndex(state, data){
      state.homeIndex = data;
    },
    saveCategories(state, data){
      state.indicesList = data;
    },
    saveShortList(state, data){
      state.shortList = data;
    }
  },
  actions: {
    getHomeIndex({commit}){
      return new Promise( (resolve, reject) => {
        axios.get("/fake-index-map.json")
          .then(d => {
            commit('saveHomeIndex', d.data);
            return resolve(d.data)
          })
          .catch(e => {
            return reject(e);
          });
      } );
    },
    getIndicesList({commit}){
      return new Promise( (resolve, reject) => {
        let url = `${server}/end/modulos`;
        axios.get(url)
          .then(d => {
            commit('saveCategories', d.data);
            return resolve(d.data)
          })
          .catch(e => {
            return reject(e);
          });
      } );
    },

    getShortList(/*{commit}*/){
      return new Promise( (resolve, reject) => {
        // axios.get("/fake-index-map.json")
        //   .then(d => {
        //     commit('saveHomeIndex', d.data);
        //     return resolve(d.data)
        //   })
        //   .catch(e => {
        //     return reject(e);
        //   });

        // data = await d3.csv("/path/to/file.csv")
        csv(`/short.csv`)
          .then(d => {
            this.commit('saveShortList', d);
            return resolve(d);
          })
          .catch( e => {
            return reject(e);
          });
      } );
    },
  },
  modules: {
  }
})

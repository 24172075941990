/*
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {ErrorMeta, IndicesMeta} from '@/router/meta.js'

/*
 * DEFINE THE MAIN ROUTES
 * --------------------------------------------------------------------------------
 */

const routes = [
  // HOME
  { 
    path: '/', 
    name: 'Home', 
    component: Home 
  },
  // CATEGORIES
  { 
    path: '/categorias', 
    name: 'Categories', 
    component: () => import('@/views/Categories.vue') 
    // meta: IndicesMeta
  },
  // INDICES
  { 
    path: '/indicadores/:category', 
    name: 'Indices', 
    component: () => import('@/views/Indices.vue'), 
    meta: IndicesMeta
  },
  // INDICE
  { 
    path: '/indicadores/:category/:indice', 
    name: 'Indice', 
    component: () => import('@/views/Indice.vue'), 
    meta: IndicesMeta
  },
  // ABOUT
  { 
    path: '/acerca-de', 
    name: 'About', 
    component: () => import('@/views/About.vue')
  },
  // TEST
  { 
    path: '/test', 
    name: 'Test', 
    component: () => import('@/views/Test.vue')
  },
  // 400
  {
    path : '/:pathMatch(.*)*',
    name : '404',
    component: () => import('@/views/404.vue'),
    meta : ErrorMeta
  }
]

/*
 * CREATE THE ROUTER
 * --------------------------------------------------------------------------------
 */
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from) {
    if(to.name === from.name){
      return;
    }
    else{
      return { top: 0 }
    }
  }
})

export default router

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch("getShortList");
      store.dispatch("getHomeIndex");
      store.dispatch("getIndicesList");
    });
  },
};
</script>
<template>
  <header class="sg_hd">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <h2>
            <a class="inmujeres" href="https://www.gob.mx/inmujeres"
              >Inmujeres</a
            >
            <a href="https://www.semujeres.cdmx.gob.mx/" class="semujeres"
              >Gobierno de la Ciudad de México | Secretaría de las Mujeres</a
            >
          </h2>
        </div>
        <div class="col-sm-6">
          <nav>
            <ul>
              <li><router-link :to="{ name: 'Home' }">Inicio</router-link></li>
              <li>
                <router-link :to="{ name: 'Categories' }"
                  >Estructura</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'About' }"
                  >Sobre el SINGE</router-link
                >
              </li>
              <!-- <li><router-link to="/test">Test</router-link> </li>-->
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <div class="sg_nameapp">
    <h2><strong>SINGE</strong> | Sistema de Indicadores de Género</h2>
  </div>
  <p class="text-center mb-0"><img src="/img/cit-2023.png"></p>
  <router-view />
  <section class="sg_smf">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="text-center">
            Este producto es generado con recursos del
            <a
              href="https://www.gob.mx/inmujeres/acciones-y-programas/fortalecimiento-a-la-transversalidad-de-la-perspectiva-de-genero"
              >Programa de Fortalecimiento a la Transversalidad de la
              Perspectiva de Género</a
            >. Empero el
            <a href="https://www.gob.mx/inmujeres"
              >Instituto Nacional de las Mujeres</a
            >
            no necesariamente comparte los puntos de vista expresados por las
            (los) autoras (es) del presente trabajo.
          </p>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row mb-4">
        <div class="col-12">
          <h2>
            <a href="https://www.semujeres.cdmx.gob.mx/" class="semujeres_w"
              >Gobierno de la Ciudad de México | Secretaría de las Mujeres</a
            >
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <h4 class="mt-4 mb-3">Atención ciudadana</h4>
          <p>
            <strong>Dirección:</strong> Avenida Morelos 20 Colonia Centro,<br />
            Alcaldía Cuauhtémoc C.P. 06000, <br />
            Ciudad de México
          </p>
          <p><strong>Teléfono:</strong> 5555122836</p>

          <p>
            <strong>Horarios:</strong><br />
            Lunes a Jueves 9:00 a 17:00 hrs., <br />
            Viernes de 9:00 a 15:00 hrs.
          </p>
        </div>
        <div class="col-sm-4">
          <ul class="mt-4">
            <li><router-link to="/acerca-de">Acerca del SINGE</router-link></li>
            <li>
              <a href="https://semujeresdigital.cdmx.gob.mx/" target="_blank"
                >Plataforma de capacitación: Semujeres Digital</a
              >
            </li>
            <li>
              <a
                href="https://cajadeherramientaspeg.cdmx.gob.mx/"
                target="_blank"
                >Caja de herramientas con perspectiva de género</a
              >
            </li>
            <!-- <li>
              <a href="https://www.cdmx.gob.mx/lgacdmx"
                >Licencia de Gobierno Abierto CDMX</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
// import MapCategory from "@/components/MapCategoryGraph.vue";
import InlineSvg from "vue-inline-svg";
export default {
  setup() {
    const store       = useStore();
    const homeIndex   = computed(() => store.state.homeIndex);
    const categories  = computed(() => store.state.indicesList);
    const indices     = computed(() => store.getters.getLatestIndices);

    const getModule = (indice) => {
      let id = indice.module_id;
      let module = categories.value.find((d) => d.id == id);
      return module;
    };

    console.log("categories", categories.value);

    return {
      // COMPUTED
      homeIndex,
      categories,
      indices,

      // METHODS
      getModule,
    };
  },
  components: { InlineSvg /*, MapCategory*/ },
};
</script>
<template>
  <section class="pb-4 mb-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-4 pb-2">
            Sistema de Indicadores de Género de la Ciudad de México,
            <strong>SINGE</strong>
          </h1>
        </div>
        <div class="col-sm-11">
          <p class="sg_lead">
            Conoce y utiliza indicadores clave sobre la condición y posición de
            las mujeres de la Ciudad de México y el avance en el cierre de
            brechas para lograr la igualdad sustantiva.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="sg_subcontent pt-0">
    <div class="container">
      <!-- indicador-->
      <!-- <div class="row box" v-if="homeIndex">
        <div class="row mb-4 pb-4">
          <div class="col-sm-8 offset-sm-2">
            <h2 class="text-center">
              Explora <strong>indicadores de género</strong> en
              <strong>datos abiertos</strong>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <h3>{{ homeIndex.title }}</h3>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-4 offset-sm-1">
            <p>{{ homeIndex.description }}</p>
            
            <div class="gf_separator bottom"></div>
            <h4>Módulo o componente</h4>
            <p>
              <router-link
                :to="{
                  name: 'Indices',
                  params: { category: homeIndex.modulo.slug },
                }"
                class="sg_t_link"
              >
                {{ homeIndex.modulo.name }}</router-link
              >
            </p>
            <div class="gf_separator bottom"></div>
            <h4>Categoría</h4>

            <div
              v-for="category of homeIndex.modulo.categories"
              :key="`cat-item-${category.id}`"
            >
              <p>{{ category.name }}</p>
            </div>

            <div class="gf_separator bottom"></div>
            <p class="pt-4"><a class="sg_btn" href="#">Ver indicador</a></p>
          </div>
          <div class="col-sm-6">

            <map-category
              v-if="homeIndex"
              :data="homeIndex.data"
              :config="homeIndex.graphs[0]"
            />
            <p class="mt-4 text-right">
              Fuente: <strong>{{ homeIndex.source }}</strong>
            </p>
          </div>
        </div>
      </div> -->
      <!-- indicador ends-->
    </div>
    <!-- categorías-->
    <div class="container mt-4 mb-4 pb-4">
      <div class="row mt-4 pt-4">
        <div class="col-12 mt-4 pt-4">
          <h2 class="mt-4 pt-4">Módulos</h2>
        </div>
      </div>
    </div>
    <div v-if="categories">
      <div v-for="category of categories" :key="`cat-item-${category.id}`">
        <router-link
          :to="{ name: 'Indices', params: { category: category.slug } }"
          class="sg_link_cat"
        >
          <div class="container">
            <div class="row">
              <div class="col-3 col-sm-2 text-center">
                <div class="svg_icon">
                  <inline-svg
                    :src="require('../components/icons/vida-v2.svg')"
                  />
                </div>
              </div>
                <div class="col-9 col-sm-10">
                  {{ category.name }}

                  <div v-if="category.categories.length > 0">
                    <span
                      ><span class="cat_title mr-1">{{
                        category.categories.length == 1
                          ? "Categoría"
                          : "Categorías"
                      }}</span>
                      <span
                        v-for="category of category.categories"
                        :key="`cat-item-${category.id}`"
                        class="cat"
                      >
                        {{ category.name }}
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <span><span class="cat_title">Categoría: </span>
                    <span class="cat"> Sin categorías</span></span>
                  </div>                
                  <span
                    ><strong
                      >{{ category.indices.length }} indicadores</strong
                    ></span
                  >
                  <span>{{ category.description }}</span>
                </div>
                
              
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="container mt-4 pt-4">
      <!-- indicador-->
      <div class="row box mt-4">
        <div class="col-12">
          <div class="row mb-4">
            <div class="col-sm-8 offset-sm-2">
              <h2 class="text-center">Indicadores recientes</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-10 offset-sm-1">
            <div class="table">
              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Módulo o componente</th>
                    <th>Categoría</th>
                    <th>Formato disponible</th>
                  </tr>
                </thead>
                <tbody v-if="indices">
                  <tr
                    v-for="indice of indices"
                    :key="`indice-link-${indice.id}`"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'Indice',
                          params: {
                            category: getModule(indice).slug,
                            indice: indice.slug,
                          },
                        }"
                        class="sg_t_link"
                        >{{ indice.title }}</router-link
                      >
                    </td>
                    <td>{{ getModule(indice).name }}</td>
                    <td>{{ indice.categoria.name }}</td>
                    <td>
                      <b class="sg_formats">CSV</b>
                      <b class="sg_formats">JSON</b>
                      <b class="sg_formats">XLS</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- indicador ends-->
    </div>
  </section>
</template>
